import React from "react";
import headspace from "../../../../assets/images/refinance/headspace.svg";
import percentage from "../../../../assets/images/refinance/percentage.svg";
import present from "../../../../assets/images/refinance/present.svg";
import brain from "../../../../assets/images/refinance/brain.svg";
import pocketMoney from "../../../../assets/images/refinance/pocket-money.svg";
import calculator from "../../../../assets/images/refinance/calculator.svg";
import binoculars from "../../../../assets/images/refinance/binoculars.svg";
import wheel from "../../../../assets/images/refinance/wheel.svg";
import carNavy from "../../../../assets/images/refinance/car-navy.svg";
import carOrange from "../../../../assets/images/refinance/car-orange.svg";
import ribbon from "../../../../assets/images/refinance/ribbon.svg";

export const getRefinanceImage = (pictogramName?: string) => {
  switch (pictogramName ?? "") {
    case "headspace":
      return <img src={headspace} />;
    case "percentage":
      return <img src={percentage} />;
    case "present":
      return <img src={present} />;
    case "pocketMoney":
      return <img src={pocketMoney} />;
    case "calculator":
      return <img src={calculator} />;
    case "binoculars":
      return <img src={binoculars} />;
    case "wheel":
      return <img src={wheel} />;
    case "carNavy":
      return <img src={carNavy} />;
    case "carOrange":
      return <img src={carOrange} />;
    case "ribbon":
      return <img src={ribbon} />;
    default:
      return <img src={brain} />;
  }
};
